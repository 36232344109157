<template>
  <div class="wrapper-track">
    <div id="container"></div>
    <div class="staff-list">
      <div class="title">查询条件</div>
      <div class="list-header">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="选择部门">
            <el-select size="small" v-model="formInline.departmentId" clearable placeholder="选择部门">
              <el-option
                v-for="item in bumen"
                :label="item.name"
                :value="item.id"
                :key="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择人员">
            <el-select size="small" v-model="formInline.staffId" clearable placeholder="选择人员">
              <el-option
                v-for="item in yuangong"
                :label="item.name"
                :value="item.id"
                :key="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择日期">
            <el-date-picker
              v-model="formInline.date"
              style="width: 150px"
              value-format="timestamp"
              type="date"
              size="small"
              placeholder="选择日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item style="width: 92%;" class="form-btn">
            <el-button size="small" type="primary" @click="onSubmit" style="margin-bottom: 20px;width:100%;">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="title">{{ dayjs(formInline.date).format('YYYY-MM-DD') }} 轨迹记录</div>
      <div class="staff-content">
        <div v-if="trackData.length>0">
          <div v-for="(item, index) in trackData" :key="index">
            <div class="section-number" @click="queryTrack(item)">第{{ trackData.length-index }}段</div>
            <div class="track-recore">
              <div class="recore-left">
                <p>用时：</p>
                <p>总里程：</p>
                <p>平均速度：</p>
                <p>开始时间：</p>
                <p>结束时间：</p>
              </div>
              <div class="recore-right">
                <p>{{ dateSecond(item.duration) }}</p>
                <p>{{ item.distance ? parseFloat((item.distance / 1000).toFixed(2)) : 0 }}km</p>
                <p>{{ item.averageSpeed ? parseFloat((item.averageSpeed * 3.6).toFixed(2)) : 0 }}km/h</p>
                <p>{{ item.startTime ? dayjs(item.startTime).format('YYYY-MM-DD HH:mm:ss') : '-' }}</p>
                <p>{{ item.endTime ? dayjs(item.endTime).format('YYYY-MM-DD HH:mm:ss') : '-' }}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else style="margin-top:20px;">暂无记录</div>
      </div>
    </div>
    <div class="staff-status">
      {{ dayjs(formInline.date).format('YYYY-MM-DD') }}
    </div>
    <div class="input-card">
      <h4>轨迹回放控制</h4>
      <div class="input-item">
        <input type="button" class="btn" value="开始动画" id="start" @click="startAnimation()"/>
        <input type="button" class="btn" value="暂停动画" id="pause" @click="pauseAnimation()"/>
      </div>
      <div class="input-item">
        <input type="button" class="btn" value="继续动画" id="resume" @click="resumeAnimation()"/>
        <input type="button" class="btn" value="停止动画" id="stop" @click="stopAnimation()"/>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue/dist/vue.js'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      formInline: {
        date: 0
      },
      map: null,
      marker: null,
      infoWindow: null,
      markers: [],
      lineArrs: [],
      polyLines: [],
      lineArr: [
          // [108.904848, 34.231743],
          // [108.909561, 34.239321],
          // [108.915196, 34.236616],
          // [108.918353, 34.235232],
          // [108.919434, 34.234558],
          // [108.922024, 34.233272],
          // [108.922223, 34.232873],
          // [108.917385, 34.225359],
          // [108.911591, 34.216328],
          // [108.901463, 34.204965],
          // [108.889726, 34.205025],
          // [108.889683, 34.211502],
          // [108.889855, 34.236908],
          // [108.890026, 34.247622],
          // [108.899124, 34.244075],
          // [108.895176, 34.234993],
          // [108.897343, 34.234283],
          // [108.899661, 34.233254],
          // [108.903716, 34.231484],
      ],
      polyline: [],
      passedPolyline: null,
      trackData: [],
      trajectoryList: [],
      pointList: [],
      bumen: [],
      yuangong: [],
      department: [],
      defaultProps: {
        children: "childTree",
        label: "name",
      },
      roleId: 0,
      mAddress: '',
      pickerOptions: {
        disabledDate: time => {
          let d = dayjs(time).format("YYYYMMDD").valueOf();
          let n = dayjs().format("YYYYMMDD").valueOf();
          return d < n;
        }
      },
    };
  },
  methods: {
    dayjs,
    // 初始化地图
    initMap() {
      this.map = new AMap.Map("container", {
        resizeEnable: true,
        // center: [108.939645, 34.343207],
        zooms: [3,20],
        expandZoomRange: true,
        zoom: 16, //地图显示的缩放级别
      });

      this.getCurrentLocation()
    },
    
    markerClick(e){
      var lnglat = [e.target.getExtData().longitude, e.target.getExtData().latitude]
      var geocoder = new AMap.Geocoder({})
      geocoder.getAddress(lnglat, (status, result) => {
        if(status === 'complete' && result.info === 'OK') {
          this.mAddress = result.regeocode.formattedAddress
        }
      })
      setTimeout(()=>{
        this.infoWindow = new AMap.InfoWindow({
          isCustom: true
        });
        let that = this
        let InfoContent = Vue.extend({
          template: `<div class='info'>
            <div class='info-name'>
              <span>${ e.target.getExtData().staffName?e.target.getExtData().staffName:'未知' }</span>
              <span @click='close' style='cursor:pointer;'>×</span>
            </div>
            <div class='info-phone'>
              <span>联系方式：${ e.target.getExtData().phone?e.target.getExtData().phone:'未知' }</span>
              <span @click='trackBack' style='cursor:pointer;'>轨迹回放</span>
            </div>
            <p>记录时间：${ dayjs(e.target.getExtData().reportTime).format('HH:mm:ss') }</p>
            <p>当前速度：${ parseFloat((e.target.getExtData().speed * 3.6).toFixed(2)) }km/h</p>
            <p>当前位置：${ that.mAddress?that.mAddress:'未知' }</p>
          </div>`,
          data() {
            return {};
          },
          methods: {
            close() {
              that.infoWindow.close()
            },
            trackBack() {
              that.infoWindow.close()
              that.startAnimation()
            }
          },
        });
        let component = new InfoContent().$mount();
        this.infoWindow.setContent(component.$el);
        this.infoWindow.open(this.map, e.target.getPosition());
      }, 500)
    },

    // 初始化所有marker
    initAllMarker() {
      let lineArr = []
      this.map.remove(this.markers)
      this.map.remove(this.polyLines)
      this.map.remove(this.lineArrs)

      // 所有点
      this.trajectoryList.forEach(v => {
        lineArr.push([v.longitude, v.latitude])
      })

      // 关键点
      this.pointList.forEach(v => {
        this.marker = new AMap.Marker({
          map: this.map,
          position: [v.longitude, v.latitude],
          icon: require("../../img/position.png"),
          offset: new AMap.Pixel(-8, -8),
          autoRotation: true
        });
        this.marker.setExtData(v);
        this.marker.on('click', this.markerClick);
         
        this.markers.push(this.marker)
      })
      this.initFirstMarker(lineArr)
      this.lineArr = lineArr
    },

    initFirstMarker(lineArr) {
      // 绘制轨迹
      let polyline = new AMap.Polyline({
        map: this.map,
        path: lineArr,
        // showDir: true,
        strokeColor: "#28F", //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, //线宽
        // strokeStyle: "solid"  //线样式
      });

      let passedPolyline = new AMap.Polyline({
        map: this.map,
        path: lineArr,
        strokeColor: "#1dd1a1", //线颜色
        // strokeOpacity: 1,     //线透明度
        strokeWeight: 6, //线宽
        // strokeStyle: "solid"  //线样式
      });

      this.marker.on("moving", function (e) {
        passedPolyline.setPath(e.passedPath);
      });
      this.polyLines.push(polyline)
      this.lineArrs.push(passedPolyline)
      // 将marker区域展示在地图中心
      this.map.setFitView();
    },

    // 获取当前定位
    getCurrentLocation() {
      // 点击获取当前位置
      var geolocation = new AMap.Geolocation({
        // 是否使用高精度定位，默认：true
        enableHighAccuracy: true,
        // 设置定位超时时间，默认：无穷大
        timeout: 10000,
        // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
        buttonOffset: new AMap.Pixel(10, 20),
        //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        zoomToAccuracy: true,     
        //  定位按钮的排放位置,  RB表示右下
        buttonPosition: 'RB'
      })

      this.map.addControl(geolocation)
      geolocation.getCurrentPosition()
    },

    startAnimation() {
      this.marker.moveAlong(this.lineArr, 2000);
    },

    pauseAnimation() {
      this.marker.pauseMove();
    },

    resumeAnimation() {
      this.marker.resumeMove();
    },

    stopAnimation() {
      this.marker.stopMove();
    },

    onSubmit() {
      this.loadList()
    },

    load_bumen() {
      this.$ajax.get("departmentTree").then((res) => {
        this.bumen = this.getTreeData(res.data);
      });
    },

    load_yuangong() {
      this.$ajax.get("staffDueryAll").then((res) => {
        this.yuangong = res.data;
      });
    },

    getTreeData(data) {
      let arr = [];
      if (Array.isArray(data)) {
        let sss = (d) => {
          d.forEach((item, index) => {
            arr.push({
              id: item.id,
              name: item.name,
            });
            if (item.childTree.length > 0) {
              sss(item.childTree);
            }
          });
        };
        sss(data);
      }
      return arr;
    },

    dateSecond(val) {
      if (val) {
        let s = val % 60
        let _s = val - s

        let m = (_s / 60) % 60

        let h = (_s / 60 - m) / 60

        return `${h>9 ? h:'0'+h}:${m>9 ? m:'0'+m}:${s>9 ? s:'0'+s}`
      } else {
        return '00:00:00'
      }
    },

    // 查询人员轨迹记录列表
    loadList() {
      this.$ajax.post("queryStaffMileage",{
        date: this.formInline.date,
        staffId: this.formInline.staffId?this.formInline.staffId:null,
      }).then((res) => {
        this.trackData = res.data;
      });
    },

    // 查询人员指定里程轨迹
    queryTrack(item){
      this.map.remove(this.markers)
      this.map.remove(this.polyLines)
      this.map.remove(this.lineArrs)
      
      // if(this.trackData.length>0){
      this.queryStaffTrack(item.mileageCode)
      if(this.infoWindow){
        this.infoWindow.close()
      }
      // }else {
      // }
    },

    queryStaffTrack(mileageCode) {
      // this.trackData.forEach(v=>{
        this.$ajax.post("queryStaffTrack",{
          mileageCode: mileageCode
        }).then((res) => {
          this.trajectoryList = res.data.trajectoryList;
          this.pointList = res.data.pointList;
          this.initAllMarker()
        });
      // })
    },

  },
  mounted() {
    this.formInline.date = new Date(new Date().toLocaleDateString()).getTime()
    this.initMap();
    this.load_bumen();
    this.load_yuangong();
  },
  beforeDestroy() {
    this.map && this.map.destroy();
  }
};
</script>

<style scope>
/* @import "https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css"; */
.wrapper-track {
  position: relative;
}

#container {
  width: 100% - 5%;
  height: 825px;
  cursor: pointer;
  position: relative;
}

.input-card {
  padding: 0 20px 10px;
  position: fixed;
  bottom: 40px;
  right: 80px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(180, 180, 180);
  color: #999;
  font-size: 15px;
}

.input-card .btn {
  margin-right: 1.2rem;
  margin-bottom: 0.7rem;
  width: 6rem;
  border: 1px solid rgb(37, 150, 255);
  border-radius: 10px;
  background: #FFF;
  color: rgb(37, 150, 255);
  cursor: pointer;
}

.input-card .btn:last-child {
  margin-right: 0;
}

.staff-list {
  width: 240px;
  height: 825px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1000;
  background: rgb(0,0,0,0.7);
}

.list-header .el-form-item__content {
  width: 65%;
}

.form-btn .el-form-item__content {
  width: 100%;
}

.list-header .el-form-item__label {
  color: #FFF;
}

.list-header .el-form-item {
  margin: 15px 0 0 10px;
}

.staff-content {
  padding: 0 10px;
  color: #FFF;
  font-size: 14px;
  height: 500px;
  overflow: auto;
}

.staff-content .section-number {
  width: 60px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: #279cb9;
  border-radius: 3px;
  margin-top: 10px;
  cursor: pointer;
}

.section-number:hover {
  background: #1c6679;
}

.track-recore {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.track-recore p {
  line-height: 20px;
}

.staff-list .title {
  line-height: 40px;
  color: #FFF;
  background: #279cb9;
  font-size: 14px;
  padding: 0 10px;
}

.staff-status {
  width: 60%;
  height: 40px;
  position: absolute;
  top: 10px;
  right: 14%;
  background: rgb(238,247,229, 0.8);
  color: #51C41B;
  border: 1px solid #82c464;
  text-align: center;
  line-height: 40px;
  border-radius: 5px;
  font-size: 14px;
}
.wrapper-track .amap-marker-label{
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  background-color: #FFF;
  padding: 0 10px;
  border: 1px solid #ccc;
}

.info{
  position: relative;
  top: 0;
  right: 0;
  /* min-width: 0; */
  width: 300px;
  background-color: #FFF;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  padding: 0 10px;
  border: 1px solid #ccc;
}

.info .info-name {
  font-size: 30px;
  line-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
}

.info-name span:nth-child(1) {
  font-size: 18px;
  font-weight: bold;
}

.info .info-phone {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.info-phone span:nth-last-child(1) {
  color: #279cb9;
}
</style>
